export default {
    menus() {
        return [
            {
                label: '首页',
                pages: [
                    { label: '欢迎使用后台系统', path: 'home' },
                ]
            }, {
                label: '现场督导',
                pages: [
                    { label: '标准管理', path: 'standardInfo' },
                    { label: '点位管理', path: 'pointPosition' },
                    { label: '考核管理', path: 'problemInfo' },
                    { label: '点位问题审核', path: 'problemInfoCheck' },
                    { label: '点位整改', path: 'problemInfoRectify' },
                    { label: '问题整改初审', path: 'problemInfoRectifyFirstCheck' },
                    { label: '问题整改终审', path: 'problemInfoRectifyEndCheck' },
                ]
            }, {
                label: '基础设置',
                pages: [
                    { label: '数据字典', path: 'dict' },
                    { label: '创城办', path: 'createCityOffice' },
                    { label: '责任单位', path: 'responsibleUnit' },
                    { label: '后台用户', path: 'userList' },
                    { hidden: true, label: '后台用户详情', path: 'userDetail' },
                    { label: '权限管理', path: 'roleList' },
                    { hidden: true, label: '权限详情', path: 'roleDetail' },
                ]
            }
        ]
    },
    filter(hidden, paths) {
        const menus = this.menus()
        for (let menuIndex = menus.length - 1; menuIndex >= 0; menuIndex--) {
            const menu = menus[menuIndex]
            if (menu != null && menu.pages != null) {
                for (let pageIndex = menu.pages.length - 1; pageIndex >= 0; pageIndex--) {
                    let splice = false
                    const page = menu.pages[pageIndex]
                    if (hidden === true && !page.hidden) {
                        splice = true
                    }
                    if (hidden === false && page.hidden) {
                        splice = true
                    }
                    if (paths != null && paths.length > 0 && !paths.includes(page.path)) {
                        splice = true
                    }
                    if (splice) {
                        menu.pages.splice(pageIndex, 1)
                    }
                }
            }
            if (menu == null || menu.pages == null || menu.pages.length == 0) {
                menus.splice(menuIndex, 1)
            }
        }
        return menus
    }
}